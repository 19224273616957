.policy-content {
    opacity: 1 !important;
    color: black !important;
    & > * {
        opacity: 1 !important;
        color: black !important;
      }
}

.policy-content * {
    opacity: 1;
    color: black;
  }

.dropdown-wrapper {
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    padding-bottom: 78px;
    .dropdown-item {
        border: 1px solid $brand-border;
        border-radius: 6px;
        margin: 8px;
        .tell-me-more {
            position: absolute;
            right: 52px;
            color: #2958FF;
            display: inline-flex;
            img {
                display: inline-block;
                margin-right: 4px;
            }
        }
        .dropdown-panel {
            height: calc(100vh - 550px);
            max-height: calc(100vh - 550px);
            overflow-y: auto;
            h3 {
                font-size: 0.9em;
                text-transform: uppercase;
                font-weight: 600;
                color: hwb(0 42% 58%);
            }
            .audit-point {
                margin-top: 4px;
                .title {
                    margin-top: 6px;
                    font-weight: 600;
                }
                .icon-wrapper {
                    display: inline;
                }
                .point-content {
                    color: #6a6a6a;
                    a {
                        text-decoration: underline;
                        cursor: pointer;
                        color: #000;
                    }
                    & > * {
                        opacity: 1 !important;
                        color: black !important;
                        /* Add other styles */
                      }
                }
            }
        }
    }
    .score {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 40px;
    }
    
    .score-text {
        line-height: 1;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        z-index: 1;
    }
    
    .svg-pi {
        width: 38px;
        height: 38px;
        transform: rotate(-90deg); /* Fix the orientation */
        position: absolute;
        top: 0;
        left: 0;
    }
    
    .svg-pi-track {
        stroke: #ddd;
    }
    
    .svg-pi-indicator {
        stroke-dasharray: 94.25px; /* DashArray = Track's or indicator's Circumference */
        stroke-linecap: round;
    }
    
    .svg-pi-track,
    .svg-pi-indicator {
        cx: 19px;
        cy: 19px;
        r: 15px;
        fill: transparent;
        stroke-width: 4px;
    }

}

.results-loading {
    margin-top: 60px;
    .loading-item {
        height: 56px;
        width: 100%;
        margin: 8px;
        border: 1px solid $brand-border;
        border-radius: 6px;
        padding: 12px 16px;
        .skeleton-title {
            .score, 
            .title {
                display: inline-block;
            }
        }
        &.open {
            height: calc(100vh - 550px);
        }
    }
}

.type-select-wrapper {
    width: 100%;
    .option {
        padding: 16px;
        border-radius: 6px;
        border: 2px solid $brand-border;
        margin: 18px 0;
        cursor: pointer;
        .radio {
            position: relative;
            height: 18px;
            width: 18px;
            border-radius: 50%;
            border: 2px solid $brand-border;
            &::after {
                content: '';
                position: absolute;
                border-radius: 50%;
                opacity: 0;
                transition: all 0.45s ease-in-out;
            }

        }
        &.selected {
            border-color: #000;
            .radio {
                border-color: #000;
                &::after {
                    content: '';
                    top: 2px;
                    left: 2px;
                    height: 10px;
                    width: 10px;
                    background-color: #000;
                    opacity: 1;
                }
            }
        }
    }
}

.checkbox-row {
    margin: 4px 0;
    padding: 12px 0;
    border-bottom: 1px solid $brand-border;
    &:last-child {
        border-bottom: none;
    }
    .chakra-checkbox {
        display: flex;
        flex-direction: row-reverse;
        width: calc(100% - 40px);
        justify-content: space-between;
    }
}

.feature-card {
    background: #fff;
    border-radius: 8px;
    padding: 24px;
    position: relative;
    .slide-1-1 {
        margin-top: 48px;
    }
    .slide-1-2 {
        width: 24px;
        margin: 32px 0 24px 0;
    }
    .slide-1-3 {
        margin-bottom: 48px;
    }
    .slide-2-1 {
        margin-top: 36px;
    }
    .slide-2-2 {
        margin: 5px 0;
    }
    .slide-2-3 {
        margin: 5px 0;
    }
    .slide-2-4 {
        margin: 5px 0;
    }
    .slide-3-1 {
        margin-top: 24px;
    }
    .slide-3-2 {
        position: absolute;
        top: 234px;
        left: 40px;
        width: 80%;
    }
    .slide-3-3 {
        position: absolute;
        top: 334px;
        left: 40px;
        width: 80%;
    }
}

.file-upload {
    position: relative;
    .upload-button {
        position: absolute;
        background-color: #fff;
        left: 8px;
        top: 8px;
        bottom: 8px;
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        padding: 0 20px;
        pointer-events: none;
        .icon {
            z-index: 99;
            margin-right: 8px;
        }
    }
    .filename-tag {
        position: absolute;
        left: 128px;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        color: #000;
    }
}