.docx-preview-upload-preview {
    .docx-wrapper>section.docx {
        width: auto !important;
        margin-bottom: 120px !important;
    }
}

.verify-metadata-modal {
    max-width: 80vw !important;
    max-height: 80vh !important;
    .verify-preview-container {
        .docx-wrapper {
            max-height: calc(70vh - 100px) !important;
            min-height: auto !important;
            overflow-y: auto !important;
            margin-bottom: 0 !important;
            max-width: 100% !important;
        }
    }
}


.doc_location_highlight {
    background-color: #95eab3 !important;
} 