.search-results {
    .selected {
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
        border: 1px solid #00B870;
        background-color: #fff;
    }
}

.omni-search-wrapper {
    .suggested-search-term {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      color: #6a6a6a;
      font-size: 1.1rem;
      width: 100%;
      padding: 8px 12px;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: background-color 0.2s ease;
  
      &:hover {
        background-color: rgba(256,256,256,0.6);
      }
  
      .arrow-icon {
        opacity: 0;
        transform: translateX(-10px);
        transition: opacity 0.2s ease, transform 0.2s ease;
      }
  
      &:hover .arrow-icon {
        opacity: 1;
        transform: translateX(0);
      }
    }
  
    .keyboard-shortcuts-info {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
    }
  }