.analysis-history-wrapper {
    width: 100%;
    .card {
        padding-left: 60px;
        position: relative;
        .ongoing-indicator {
            height: 24px;
            width: 24px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
        }
    }
}

.date-range-picker-wrapper {
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid #cfcfcf !important;
    .date-range-picker {
        box-shadow: none;
        .day-today {
            color: #00B870 !important;
            font-weight: 600 !important;
        }
        .day-selected {
            color: #00B870;
            &.rdp-range_start{
                .rdp-day_button {
                    background-color: #00B870;
                    color: #fff;
                    border-color: transparent;
                }
            }
            &.rdp-range_end {
                .rdp-day_button {
                    background-color: #00B870;
                    color: #fff;
                    border-color: transparent;
                }
            }
        }
        .day {
            color: #4b4b4b;
        }
        .rdp-chevron {
                fill: #00B870 !important;
        }
    }

}