@font-face {
  font-family: 'Garnett';
  src: url('../../public/fonts/Garnett-Medium.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Garnett';
  src: url('../../public/fonts/Garnett-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../../public/fonts/Inter-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../../public/fonts/Inter-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../../public/fonts/Inter-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}



body {
  background-color: #f5f5f5 !important;
  font-family: 'Garnett', sans-serif !important;
}
p {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: #626a6f;
}
.card {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.03);
}

.navigation-header {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 80px;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 9;
  .header-logo {
    background-image: url('./../../images/logo-header.svg');
    height: 28px;
    width: 56px;
    background-repeat: no-repeat;
    position: absolute;
    top: 28px;
    left: 50%;
    transform: translateX(-50%);
  }
  .navigation-items {
    margin-top: 6em;
    .nav-item {
      margin: 2em 0;
      cursor: pointer;
      padding: 0.8em;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        background-color: $brand-primary-highlight;
      }
    }
  }
}

.central-card {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  padding: 32px 0;
  h1.card-heading {
    font-size: 1.4em;
    font-weight: 700;
  }
}

.page-content-margin {
  margin-left: 80px;
}

.card {
  background-color: #fff;
  border-radius: 8px;
}

.icon-wrapper {
  display: inline-block;
}

.enter-icon {
  height: 22px;
  width: auto;
}
