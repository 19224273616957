.dispute_analysis-page {
    .analysis-title {
        font-family: 'Garnett', sans-serif;
        margin-left: 30px;
        margin-top: 16px;
        font-size: 1.3rem;
        color: #000;
        font-weight: 600;
    }
    .dispute_analysis-view {
        min-height: calc(100vh - 80px);
        background-color: #fff;
        margin: 16px 30px 20px 30px;
        border-radius: 8px;
        padding: 1.2rem;
    }
    .modifiers-bar {
        background-color: #fff;
        z-index: 999;
        border: 1px solid #EDEDED;
        box-shadow: 0 2px 6px 0 rgba(0,0,0,0.08);
        margin: 0 16px;
        border-radius: 4px;
    }
    .summary-container {
        ul, p, span {
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            color: #626a6f;
            list-style: none;
        }
        h1 {
            font-family: 'Garnett Medium', sans-serif;
            margin: 12px 0 8px 0;
            font-size: 18px;
        }
    }
    .doc-preview {
        cursor: pointer;
        background: #7d7d7d;
        border-radius: 4px;
        transition: all 0.25s ease-in-out;
        cursor: pointer;
        background: #7d7d7d;
        border-radius: 4px;
        height: 100%;
        position: absolute;
        right: 0;
        &.analysis_tab {
            height: calc(100vh - 180px) !important;
        }
        &.snippets_tab {
            margin-top: 16px;
            height: calc(100vh - 180px) !important;
        }
        &.deliverables_tab {
            display: none;
        }
        &-container {
            .docx-wrapper {
                border-radius: 6px;
                // max-height: calc(100vh - 400px);
                overflow: scroll;
                .docx {
                    width: auto !important;
                    padding: 55pt 45pt !important;
                }
            }
        }
        .file-select-container {
            // height: 50px;
            background-color: #D6E2DE;
            padding: 12px 24px;
            .file-select {
                margin: 0 8px;
                white-space: nowrap;
                padding: 6px 8px;
                &.selected {
                    background-color: #fff;
                    border-radius: 4px;
                    font-weight: 600;
                }
            }
        }
    }
    .doc-sidebar {
    .sidebar-toggle {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99;
        cursor: pointer;
        transition: all 0.25s ease-in-out;
        &.expanded {
            top: -30px;
            left: 20px;
        }
    }
    }
}

.snippets-page {
    width: 100%;
    height: calc(100vh - 180px);
    .react-flow__background {
        background-color: #f5f5f5; /* Custom background color */
      }
    .custom-node {
        padding: 16px 24px;
        border-radius: 5px;
        background: white;
        border: 1px solid #e0e0e0;
        width: 360px;
        .node-header {
          font-weight: bold;
          margin-bottom: 8px;
        }
        .snippet-body {
            font-size: 0.95em;
        }
        .snippet-header, .instruction-header {
            font-family: 'Garnett Medium', sans-serif;
            font-size: 1em;
            color: #000;
            margin-bottom: 8px;
        }
        &.instruction-node {
            background-color: #fcfaee;
            border: 2px solid #c9c7bf;
        }
        &.faded-node {
            opacity: 0.3 !important;
            pointer-events: none !important;
            cursor: not-allowed !important;
        }
      }


.react-flow__handle {
    width: 10px !important;
    height: 10px !important;
    background-color: #00B870;
  }
}

.citation-button {
    background-color: #ededed;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 12px;
    margin: 0 5px 5px 5px;
}





.timeline-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: scroll;
    background-color: #f9f9f9;
    padding: 24px;
    .timeline-arrow {
      position: absolute;
      top: 0;
      bottom: -150px;
      width: 100%;
      height: calc(100% + 50px);
      pointer-events: none;
      line {
        stroke: #cfcfcf;
         stroke-width: 2;
      }
    }
    .event-node {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 50px;
        z-index: 99;
        .date-box {
            background-color: #00B870;
            color: white;
            font-weight: 500;
            padding: 5px 10px;
            border-radius: 4px;
            margin: 0 20px;
            text-align: center;
            width: 100%;
            max-width: 100px;
        }
  
        .detail-box {
            background-color: rgb(255, 255, 255);
            padding: 16px 24px;
            border-radius: 4px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
            width: calc(100% - 180px);
            .reference-text {
              text-decoration: underline;
              cursor: pointer;
            }
            .references-toggle {
              cursor: pointer;
              font-weight: 600;
              color: #000000;
            }
        }
    }
  }
  
  
  .export-options {
    position: absolute;
    right: 40px;
    top: 20px;
  }


  .results-wrapper {
    line-height: 1.5 !important;
    font-family: 'Inter', sans-serif;
    font-size: 1.2rem;
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 1.4rem;
      margin: 24px 0 8px 0;
      color: #00613c;
      font-weight: 700;
    }
    b {
      color: #2b2b2b;
    }
    li,
    p {
      font-size: 1.25rem;
      margin: 4px 0;
      color: #6a6a6a;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
    }
    ul {
      list-style: inside;
    }
    table, th, td {
      border: 1px solid #cfcfcf;
      padding: 4px 6px;
    }
    hr {
      margin: 12px 0;
    }
  }
  
  .text-selection-popup {
    // background-color: rgb(27, 27, 27);
    color: #fff;
    border-radius: 6px;
  }
  
  .text-appended-highlight {
    background-color: rgba(160, 255, 160, 0.7);
    padding: 2px;
    margin: 4px;
    // font-size: 16px;
    &:hover {
    background-color: rgba(160, 255, 160, 0.9);
    }
    .citation-text {
      cursor: pointer;
    }
  }
  .text-replaced-highlight {
    background-color: rgba(160, 255, 160, 0.2);
    padding: 2px;
    margin: 4px;
    color: #3b3b3b;
    .reference-id {
      background-color: #eaeaea;
      color: #000000;
      padding: 3px 8px;
      border-radius: 20px;
      cursor: pointer;
      display: inline;
      margin: 0 4px;
    }
  }
  .doc_location_highlight {
    background-color: rgba(160, 255, 160, 0.7);
    padding: 1px 0;
  }
  
  .go-back {
    display: inline-flex;
    align-items: center;
    padding: 12px;
    border-radius: 6px;
    margin-top: 12px;
    &:hover {
      background-color: #daddde;
    }
    span {
      font-weight: 700;
      margin-left: 14px;
      font-size: 14px;
      color: #6a6a6a;
      font-family: 'Inter', sans-serif;
    }
  }
  
  .preview-container {
    background: #d5d5d5;
    border-bottom-right-radius: 8px;
    width: 100%;
    position: sticky;
    margin-top: 72px;
    margin-right: 20px;
    max-width: 50vw;
    height: calc(100vh - 42px);
    top: 0;
    .docx-wrapper {
      background-color: #d5d5d5;
      color: #000;
      p, span, b {
        color: #000;
      }
      &>section.docx {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
      }
    }
    .topbar {
      background-color: #D6E2DE;
      height: 80px;
      display: flex;
      align-items: center;
      max-width: 100%;
      overflow-x: auto;
      .file-select {
        font-size: 16px;
        font-weight: 600;
        padding: 6px 16px;
        border: 2px solid #6a6a6a;
        color: #4f4f4f;
        border-radius: 50px;
        white-space: nowrap;
        margin: 0 8px;
        cursor: pointer;
        transition: all 0.25s ease-in-out;
        &.selected {
          background-color: #fff;
          color: #000;
          border-color: #000;
          box-shadow: 0 0px 4px 3px rgba(0, 0, 0, 0.08);
        }
      }
    }
  }
  
  .case-title {
    position: absolute;
    top: 20px;
    left: 112px;
  }
  
  .results-card {
    background-color: #fff;
    border-radius: 8px;
    margin: 72px 0 40px 20px;
  }
  
  .function-card {
    border: 1px solid #dfdfdf;
  }
  
  
  
  
  
  
  
  
  
  .timeline-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: scroll;
    background-color: #f9f9f9;
    padding: 24px;
    .timeline-arrow {
      position: absolute;
      top: 0;
      bottom: -150px;
      width: 100%;
      height: calc(100% + 50px);
      pointer-events: none;
      line {
        stroke: #cfcfcf;
         stroke-width: 2;
      }
    }
    .event-node {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 50px;
        z-index: 99;
        .date-box {
            background-color: #00B870;
            color: white;
            font-weight: 500;
            padding: 5px 10px;
            border-radius: 4px;
            margin: 0 20px;
            text-align: center;
            width: 100%;
            max-width: 100px;
        }
  
        .detail-box {
            background-color: rgb(255, 255, 255);
            padding: 16px 24px;
            border-radius: 4px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
            width: calc(100% - 180px);
            .reference-text {
              text-decoration: underline;
              cursor: pointer;
            }
            .references-toggle {
              cursor: pointer;
              font-weight: 600;
              color: #000000;
            }
        }
    }
  }
  
  
  .export-options {
    position: absolute;
    right: 40px;
    top: 20px;
  }