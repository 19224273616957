$brand-primary: #004732;

.login {
    position: relative;
    .login-left-container {
        background-color: $brand-primary;
        display: flex;
        align-items: center;
        width: 40vw;
        padding: 0 50px;
        position: relative;
        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url('./../../../images/pattern-1.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: left center;
        }
        .feature-carousel-wrapper {
            max-width: 100%;
            z-index: 9999999;
        }
    }
    .login-right-container {
        position: relative;
        height: 100vh;
        width: 60vw;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .adidem-logo-full {
        width: 220px;
        position: absolute;
        top: 20px;
        right: 10px;
        filter: brightness(0);
    }
}