.text-actions-wrapper {
  background-color: black;
  flex-direction: row;
  border-radius: 6px;
  overflow: hidden;
  height: 40px;
  display: flex;
  align-items: center;
}

.action-item {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.action-loader {
  color: white;
  padding: 10px 16px;
  font-weight: 600;
  flex-grow: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


.action-loader {
  .answer-loader {
    padding: 8px 20px;
    $background-color_1: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    .loading-text {
      font-size: 1rem;
      font-weight: 600;
      margin-left: 6px;
      color: #ffffff;
    }
    @keyframes loading-span1 {
      0% {
        height: 12px;
      }
      25% {
        height: 20px;
      }
      50% {
        height: 12px;
      }
      100% {
        height: 12px;
      }
    }
    @keyframes loading-span2 {
      0% {
        height: 12px;
      }
      25% {
        height: 12px;
      }
      50% {
        height: 20px;
      }
      75% {
        height: 12px;
      }
      100% {
        height: 12px;
      }
    }
    @keyframes loading-span3 {
      0% {
        height: 12px;
      }
      25% {
        height: 12px;
      }
      50% {
        height: 12px;
      }
      75% {
        height: 20px;
      }
      100% {
        height: 12px;
      }
    }
    .loading-items {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 8px;
      position: relative;
      margin-top: 6px;
    }
    .loading {
      position: relative;
      width: 44px;
      display: flex;
      position: relative;
      top: 6px;
      span {
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: $background-color_1;
        border-radius: 4px;
        position: absolute;
        bottom: 0;
        &:first-child {
          animation: loading-span1 1.3s infinite;
          left: 0;
        }
        &:nth-child(2) {
          animation: loading-span2 1.3s infinite;
          left: 10px;
        }
        &:nth-child(3) {
          animation: loading-span3 1.3s infinite;
          left: 20px;
        }
      }
    }
  }
}